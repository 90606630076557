<template>
  <div>
    <b-row>
      <b-col md="10">
        <b-form-group>
          <b-form-input
            v-if="view === false"
            :value="note.sw081s04"
            @blur="updateAction(note.sw081s01, $event)"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ note.sw081s04 }}
          </p>
        </b-form-group>
      </b-col>
      <b-col
        v-if="view === false"
        md="2"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
          class="mr-1"
          @click="deleteAction(note.sw081s01)"
        >
          <feather-icon
            icon="TrashIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('Eliminar') }}</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mixFormGeral } from '../../../mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    note: {
      type: Object,
      default: () => { },
    },
    deleteAction: {
      type: Function,
      required: true,
    },
    updateAction: {
      type: Function,
      required: true,
    },
    view: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style lang="scss">
</style>
